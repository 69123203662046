import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import TalcumClaims from "@components/layouts/talcumclaimsWrapper";
import MainBanner from "@components/talcum/mainBanner";
const OffersList = dynamic(() => import("@components/shared/offersList"));
import { useDomainContext } from "@hooks/useDomainContext";
import GetMyFreeCase from "@components/talcum/getMyFreeCase";
import WhatTalcumPowder from "@components/talcum/whatTalcumPowder";
import MiddleSection from "@components/talcum/middleSection";
import QualifyLawsuit from "@components/talcum/qualifyLawsuit";
export default function TalcumClaimsHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <TalcumClaims>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} fullWidth={true} />
            </section>
            <QualifyLawsuit />
            <WhatTalcumPowder />
            <MiddleSection />
            <GetMyFreeCase />
        </TalcumClaims>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
